import React from "react"
import styled from "styled-components"
import { Headline, Text } from "../../common/headline"
import { MdEmail } from "react-icons/md"
import { FaGlobe } from "react-icons/fa"
import { IoMdCall } from "react-icons/io"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "./i18n"

const Container = styled.div`
  width: 100%;
  background: #eee;
  padding: 2rem;
`
const Link = styled.a`
  text-decoration: none;
  color: black;
`
const Content = styled.div`
  max-width: 1200px;
  margin 0 auto;
`
const Border = styled.div`
  width: 140px;
  border-bottom: 2px solid #f36c2a;
  margin: -21px 0 15px 0;
`

const Imprint = () => {
  const intl = useIntl()
  return (
    <Container>
      <Content>
        <Headline type="h3">{intl.formatMessage(i18n.headline)} </Headline>
        <Border />
        <Headline type="h4" margin="0">
          {intl.formatMessage(i18n.name)}
        </Headline>
        <Text>
          {intl.formatMessage(i18n.address, {
            break: str => (
              <span>
                <br />
                {str}{" "}
              </span>
            ),
          })}
        </Text>
        <Headline type="h5" margin=".5rem 0 0 0">
          {intl.formatMessage(i18n.position)}
        </Headline>
        <Text>{intl.formatMessage(i18n.nameCeo)} </Text>

        <div>
          <Headline type="h4" margin="0.9rem 0 0.2rem 0">
            {intl.formatMessage(i18n.contact)}
          </Headline>
          <Link href="tel:+493085612349">
            <Text>
              <IoMdCall /> {intl.formatMessage(i18n.phone)}
            </Text>
          </Link>
          <Link href="mailto:info@tmc-home.de">
            <Text>
              <MdEmail /> {intl.formatMessage(i18n.email)}
            </Text>
          </Link>
          <Link href="http://www.tmc-home.de">
            <Text>
              <FaGlobe /> {intl.formatMessage(i18n.web)}
            </Text>
          </Link>
          <Headline type="h4" margin="0.9rem 0 0.2rem 0">
            {intl.formatMessage(i18n.register)}
          </Headline>
          <Text>
            {" "}
            {intl.formatMessage(i18n.number, {
              break: str => (
                <span>
                  {" "}
                  <br />
                  {str}{" "}
                </span>
              ),
            })}{" "}
          </Text>
        </div>
        <div>
          <Headline type="h4" margin="1.2rem 0 0.2rem 0">
            {intl.formatMessage(i18n.infoheader)}
          </Headline>
          <Text>{intl.formatMessage(i18n.infotext)}</Text>
        </div>
      </Content>
    </Container>
  )
}

export default Imprint
