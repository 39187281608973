import { defineMessages } from "react-intl"

const scope = "imprint"

export default defineMessages({
  headline: {
    id: `${scope}.headline`,
    defaultMessage: "Impressum",
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: "SOLDA GmbH",
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: " Innsbrucker Platz 4 <break>10827 Berlin</break>",
  },
  position: {
    id: `${scope}.position`,
    defaultMessage: "Geschäftsführer",
  },
  nameCeo: {
    id: `${scope}.nameCeo`,
    defaultMessage: "Marcin Czyzewski",
  },
  contact: {
    id: `${scope}.conatct`,
    defaultMessage: "Kontakt",
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: " +49 (0) 30 – 856 1234 9",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: " info@tmc-home.de",
  },
  web: {
    id: `${scope}.web`,
    defaultMessage: "www.tmc-home.de",
  },
  register: {
    id: `${scope}.register`,
    defaultMessage: "Registereintrag",
  },
  number: {
    id: `${scope}.number`,
    defaultMessage:
      "Umsatzsteuer-ID: DE 329425040 <break>Amtsgericht Charlottenburg, HRB 216317</break>",
  },

  infoheader: {
    id: `${scope}.infoHeader`,
    defaultMessage: "Haftungsausschluss",
  },
  infotext: {
    id: `${scope}.infoText`,
    defaultMessage:
      "Die bereitgestellten Informationen auf dieser Website werden regelmäßig aktualisiert, jedoch kann keine Garantie dafür übernommen werden, dass alle Angaben zu jeder Zeit vollständig und in letzter Aktualität dargestellt sind. Der Betreiber erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat der Betreiber keinen Einfluss. Für den Inhalt externer Seiten wird daher jegliche Verantwortung abgelehnt. Der Betreiber distanziert sich hiermit ausdrücklich von allen Inhalten aller verlinkten/verknüpften Seiten, die nach der Linksetzung verändert wurden, soweit solche Inhalte gegen geltendes Recht oder die guten Sitten verstoßen oder beleidigende oder persönlich herabwürdigende Äußerungen enthalten.",
  },
})
