import React from "react"
import Page from "../components/common/page"
import Imprint from "../components/page-components/imprint"

import { ContentRow } from "../components/common/shared-components/layouts"

const CONTENT_ROWS = [[<Imprint />]]

const Impressum = () => {
  return (
    <Page title="board">
      {CONTENT_ROWS.map((components, key) => (
        <ContentRow key={key} components={components} />
      ))}
    </Page>
  )
}

export default Impressum
